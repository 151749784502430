
import { defineComponent } from 'vue'
import MintMainSection from '@/components/sections/MintMainSection.vue'

export default defineComponent({
  name: 'Mint',
  components: {
    MintMainSection
  }
})
