
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import MainButton from "@/components/atoms/buttons/MainButton.vue";

export default defineComponent({
  name: "MintMainSection",
  data() {
    const schema = yup.object().shape({
      number: yup.string().required("Mint is required!"),
    });

    return {
      message: "",
      schema,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    MainButton
  },
  mounted() {
  },
  methods: {
  },
});
